import * as React from "react";
import { useNavigate } from "react-router-dom";
import { zodResolver } from "@hookform/resolvers/zod";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import { useForm } from "react-hook-form";
import { z as zod } from "zod";
import { ArrowLeft as ArrowLeftIcon } from "@phosphor-icons/react/dist/ssr/ArrowLeft";
import { Breadcrumbs, Link, MenuItem } from "@mui/material";
import BootstrapInput from "../BootstrapTextField";
import { useEffect } from "react";
import fetchData from "../../Helpers/apiCalls";
import settings from "../../settings/api";
import { useState } from "react";
import BootstrapSelect from "../BootstrapSelect";
import WarningIcon from "@mui/icons-material/Warning";
import AccountLayout from "../../pages/StandardPages/Account";
import { useTranslation } from "react-i18next";

function fileToBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            resolve(reader.result);
        };
        reader.onerror = () => {
            reject(new Error("Error converting file to base64"));
        };
    });
}

const schema = zod.object({
    avatar: zod.string().optional(),
    name: zod.string().min(1, "Name is required").max(255),
    email: zod.string().email("Must be a valid email").min(1, "Email is required").max(255),
    phone: zod.string().min(1, "Phone is required").max(15),
    company: zod.string().max(255),
    billingAddress: zod.object({
        country: zod.string().min(1, "Country is required").max(255),
        state: zod.string().min(1, "State is required").max(255),
        city: zod.string().min(1, "City is required").max(255),
        zipCode: zod.string().min(1, "Zip code is required").max(255),
        line1: zod.string().min(1, "Street line 1 is required").max(255),
        line2: zod.string().max(255).optional(),
    }),
    taxId: zod.string().max(255).optional(),
    timezone: zod.string().min(1, "Timezone is required").max(255),
    language: zod.string().min(1, "Language is required").max(255),
    currency: zod.string().min(1, "Currency is required").max(255),
});

const defaultValues = {
    avatar: "",
    name: "",
    email: "",
    phone: "",
    company: "",
    billingAddress: { country: "us", state: "", city: "", zipCode: "", line1: "", line2: "" },
    taxId: "",
    timezone: "new_york",
    language: "en",
    currency: "USD",
};

export function EditSiteForm() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const {
        // handleSubmit,
        formState: { errors },
        setValue,
        // watch,
    } = useForm({ defaultValues, resolver: zodResolver(schema) });
    const [formInfo, setFormInfo] = useState({
        id: "",
        name: "",
        code: "",
        phonePrimary: "",
        fax: "",
        email: "",
        address1: "",
        address2: "",
        city: "",
        regionId: "",
        countryId: 0,
        postal: "",
        poBox: true,
        timeZone: 0,
        locale: 0,
    });
    const [countries, setCountries] = useState([]);
    const [regions, setRegions] = useState([]);
    const [timeZones, setTimeZones] = useState([]);
    const [locales, setLocales] = useState([]);
    const [errorsMessages, setErrorsMessages] = useState([]);

    useEffect(() => {
        fetchMeSiteInfo();
        fetchCountries();
        fetchTimeZones();
        setFormInfo({
            ...formInfo,
            id: JSON.parse(localStorage.getItem("user"))?.site?.siteId,
        });
        setLocales(JSON.parse(localStorage.getItem("userOrganizationInfo"))?.locales);
        return () => {};
    }, []);

    useEffect(() => {
        if (!!formInfo.countryId) {
            fetchRegions();
        }

        return () => {};
    }, [formInfo.countryId]);

    const fetchMeSiteInfo = async () => {
        const result = await fetchData(settings.api().meSite, "GET");
        if (result.success) {
            setFormInfo({
                ...formInfo,
                id: result.data.id,
                name: result.data.name,
                code: result.data.code,
                phonePrimary: result.data.phonePrimary,
                fax: result.data.fax,
                email: result.data.email,
                address1: result.data.physicalAddress.address1,
                address2: result.data.physicalAddress.address2,
                city: result.data.physicalAddress.city,
                regionId: result.data.physicalAddress.regionId,
                countryId: result.data.physicalAddress.countryId,
                postal: result.data.physicalAddress.postal,
                poBox: result.data.physicalAddress.poBox,
            });
        }
    };

    const fetchCountries = async () => {
        const result = await fetchData(settings.api().countries, "GET");
        if (result.success) {
            setCountries(result.data);
        }
    };

    const fetchRegions = async () => {
        if (!!formInfo.countryId) {
            const result = await fetchData(settings.api(formInfo.countryId).regions, "GET");
            if (result.success) {
                setRegions(result.data);
            }
        }
    };

    const fetchTimeZones = async () => {
        const result = await fetchData(settings.api().timezones, "GET");
        if (result.success) {
            setTimeZones(result.data);
        }
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        console.log(JSON.stringify(formInfo));

        const result = await fetchData(settings.api().meSite, "PUT", formInfo);
        console.log(result);

        if (result.success) {
            setErrorsMessages([]);
            navigate("/portal/site");
        } else {
            setErrorsMessages(result.errors);
        }
    };

    // const avatarInputRef = React.useRef(null);
    // const avatar = watch("avatar");

    const handleAvatarChange = React.useCallback(
        async (event) => {
            const file = event.target.files?.[0];

            if (file) {
                const url = await fileToBase64(file);
                setValue("avatar", url);
            }
        },
        [setValue]
    );

    const handleFormChange = (e) => {
        const { name, value } = e.target;
        setFormInfo({
            ...formInfo,
            [name]: value,
        });
    };

    const navItems = [
        {
            key: "menu",
            title: "Menu",
            items: [
                { key: "edit", title: "Edit", href: "/portal/site/edit", icon: "pencil-simple" },
                { key: "custom-fields", title: "Custom Fields", href: "", icon: "paint-brush-household" },
            ],
        },
    ];

    return (
        <AccountLayout navItems={navItems} profile={{ name: formInfo.name, secondary: formInfo.email }}>
            <Box
                sx={{
                    maxWidth: "var(--Content-maxWidth)",
                    m: "var(--Content-margin)",
                    p: "var(--Content-padding)",
                    width: "var(--Content-width)",
                }}
            >
                <Stack spacing={1}>
                    <Stack spacing={1}>
                        <div>
                            <Button startIcon={<ArrowLeftIcon />} onClick={() => navigate("/portal/site")}>
                                My Site
                            </Button>
                        </div>
                        <div>
                            <Breadcrumbs aria-label="breadcrumb">
                                <Link underline="hover" color="inherit" href="/portal/site">
                                    My Site
                                </Link>
                                <Typography color="text.primary">Edit My Site</Typography>
                            </Breadcrumbs>
                        </div>
                        <div>
                            <Typography variant="h4">Edit my site</Typography>
                        </div>
                    </Stack>
                    <form onSubmit={onSubmit}>
                        <Card>
                            <CardContent>
                                <Stack divider={<Divider />} spacing={4}>
                                    <Stack spacing={3}>
                                        {/* <Typography variant="h6">Account information</Typography> */}
                                        <Grid container spacing={3}>
                                            {/* <Grid xs={12}>
                                            <Stack direction="row" spacing={3} sx={{ alignItems: "center" }}>
                                                <Box
                                                    sx={{
                                                        border: "1px dashed palette-divider",
                                                        borderRadius: "50%",
                                                        display: "inline-flex",
                                                        p: "4px",
                                                    }}
                                                >
                                                    <Avatar
                                                        src={avatar}
                                                        sx={{
                                                            "--Avatar-size": "100px",
                                                            "--Icon-fontSize": "1.5rem",
                                                            alignItems: "center",
                                                            bgcolor: "palette.background.level1",
                                                            // color: "palette.primary.main",
                                                            display: "flex",
                                                            justifyContent: "center",
                                                        }}
                                                    >
                                                        <CameraIcon fontSize="1.5rem" />
                                                    </Avatar>
                                                </Box>
                                                <Stack spacing={1} sx={{ alignItems: "flex-start" }}>
                                                    <Typography variant="subtitle1">Avatar</Typography>
                                                    <Typography variant="caption">
                                                        Min 400x400px, PNG or JPEG
                                                    </Typography>
                                                    <Button
                                                        color="secondary"
                                                        onClick={() => {
                                                            avatarInputRef.current?.click();
                                                        }}
                                                        variant="outlined"
                                                    >
                                                        Select
                                                    </Button>
                                                    <input
                                                        hidden
                                                        onChange={handleAvatarChange}
                                                        ref={avatarInputRef}
                                                        type="file"
                                                    />
                                                </Stack>
                                            </Stack>
                                        </Grid> */}
                                            <Grid md={6} xs={12}>
                                                <FormControl variant="standard" fullWidth required={true}>
                                                    <InputLabel shrink htmlFor="name-input">
                                                        {/* <strong> */}
                                                        Name
                                                        {/* </strong> */}
                                                    </InputLabel>
                                                    <BootstrapInput
                                                        id="name-input"
                                                        aria-labelledby="name-input"
                                                        fullWidth
                                                        name={"name"}
                                                        error={Boolean(errors.name)}
                                                        value={formInfo.name}
                                                        onChange={handleFormChange}
                                                    />
                                                    {errors.name ? (
                                                        <FormHelperText>{errors.name.message}</FormHelperText>
                                                    ) : null}
                                                </FormControl>
                                            </Grid>
                                            <Grid md={6} xs={12}>
                                                <FormControl variant="standard" fullWidth required={true}>
                                                    <InputLabel shrink htmlFor="email-input">
                                                        Email
                                                    </InputLabel>
                                                    <BootstrapInput
                                                        id="email-input"
                                                        aria-labelledby="email-input"
                                                        fullWidth
                                                        name={"email"}
                                                        error={Boolean(errors.email)}
                                                        value={formInfo.email}
                                                        onChange={handleFormChange}
                                                    />
                                                    {errors.email ? (
                                                        <FormHelperText>{errors.email.message}</FormHelperText>
                                                    ) : null}
                                                </FormControl>
                                            </Grid>
                                            <Grid md={6} xs={12}>
                                                <FormControl variant="standard" fullWidth required={true}>
                                                    <InputLabel shrink htmlFor="phone-input">
                                                        Phone
                                                    </InputLabel>
                                                    <BootstrapInput
                                                        id="phone-input"
                                                        aria-labelledby="phone-input"
                                                        fullWidth
                                                        name={"phonePrimary"}
                                                        error={Boolean(errors.phonePrimary)}
                                                        value={formInfo.phonePrimary}
                                                        onChange={handleFormChange}
                                                    />
                                                    {errors.phonePrimary ? (
                                                        <FormHelperText>{errors.phonePrimary.message}</FormHelperText>
                                                    ) : null}
                                                </FormControl>
                                            </Grid>
                                            <Grid md={6} xs={12}>
                                                <FormControl variant="standard" fullWidth>
                                                    <InputLabel shrink htmlFor="fax-input">
                                                        Fax
                                                    </InputLabel>
                                                    <BootstrapInput
                                                        id="fax-input"
                                                        aria-labelledby="fax-input"
                                                        fullWidth
                                                        name={"fax"}
                                                        error={Boolean(errors.fax)}
                                                        value={formInfo.fax}
                                                        onChange={handleFormChange}
                                                    />
                                                    {errors.fax ? (
                                                        <FormHelperText>{errors.fax.message}</FormHelperText>
                                                    ) : null}
                                                </FormControl>
                                            </Grid>
                                            <Grid md={6} xs={12}>
                                                <FormControl variant="standard" fullWidth required={true}>
                                                    <InputLabel shrink htmlFor="code-input">
                                                        Code
                                                    </InputLabel>
                                                    <BootstrapInput
                                                        id="code-input"
                                                        aria-labelledby="code-input"
                                                        fullWidth
                                                        name={"code"}
                                                        error={Boolean(errors.code)}
                                                        value={formInfo.code}
                                                        onChange={handleFormChange}
                                                    />
                                                    {errors.code ? (
                                                        <FormHelperText>{errors.code.message}</FormHelperText>
                                                    ) : null}
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </Stack>
                                    <Stack spacing={3}>
                                        <Typography variant="h6">Address</Typography>
                                        <Grid container spacing={3}>
                                            <Grid md={6} xs={12}>
                                                <FormControl variant="standard" fullWidth required={true}>
                                                    <InputLabel shrink htmlFor="address-line-1-input">
                                                        Address Line 1
                                                    </InputLabel>
                                                    <BootstrapInput
                                                        id="address-line-1-input"
                                                        aria-labelledby="address-line-1-input"
                                                        fullWidth
                                                        name={"address1"}
                                                        error={Boolean(errors.address1)}
                                                        value={formInfo.address1}
                                                        onChange={handleFormChange}
                                                    />
                                                    {errors.address1 ? (
                                                        <FormHelperText>{errors.address1.message}</FormHelperText>
                                                    ) : null}
                                                </FormControl>
                                            </Grid>
                                            <Grid md={6} xs={12}>
                                                <FormControl variant="standard" fullWidth required={true}>
                                                    <InputLabel shrink htmlFor="address-line-2-input">
                                                        Address Line 2
                                                    </InputLabel>
                                                    <BootstrapInput
                                                        id="address-line-2-input"
                                                        aria-labelledby="address-line-2-input"
                                                        fullWidth
                                                        name={"address2"}
                                                        error={Boolean(errors.address1)}
                                                        value={formInfo.address1}
                                                        onChange={handleFormChange}
                                                    />
                                                    {errors.address1 ? (
                                                        <FormHelperText>{errors.address1.message}</FormHelperText>
                                                    ) : null}
                                                </FormControl>
                                            </Grid>

                                            <Grid md={6} xs={12}>
                                                <FormControl variant="standard" fullWidth required={true}>
                                                    <InputLabel shrink htmlFor="city-input">
                                                        City
                                                    </InputLabel>
                                                    <BootstrapInput
                                                        id="city-input"
                                                        aria-labelledby="city-input"
                                                        fullWidth
                                                        name={"city"}
                                                        error={Boolean(errors.city)}
                                                        value={formInfo.city}
                                                        onChange={handleFormChange}
                                                    />
                                                    {errors.city ? (
                                                        <FormHelperText>{errors.city.message}</FormHelperText>
                                                    ) : null}
                                                </FormControl>
                                            </Grid>
                                            <Grid md={6} xs={12}>
                                                <FormControl variant="standard" fullWidth required={true}>
                                                    <InputLabel shrink htmlFor="postal-code-input">
                                                        Postal/Zip Code
                                                    </InputLabel>
                                                    <BootstrapInput
                                                        id="postal-code-input"
                                                        aria-labelledby="postal-code-input"
                                                        fullWidth
                                                        name={"postal"}
                                                        error={Boolean(errors.postal)}
                                                        value={formInfo.postal}
                                                        onChange={handleFormChange}
                                                    />
                                                    {errors.postal ? (
                                                        <FormHelperText>{errors.postal.message}</FormHelperText>
                                                    ) : null}
                                                </FormControl>
                                            </Grid>
                                            <Grid md={6} xs={12}>
                                                <FormControl fullWidth required={true}>
                                                    <InputLabel variant="standard" shrink={true} id="country-input-label">
                                                        Country
                                                    </InputLabel>
                                                    <BootstrapSelect
                                                        id="country-input-select"
                                                        labelId="country-input-label"
                                                        value={formInfo.countryId}
                                                        name={"countryId"}
                                                        onChange={handleFormChange}
                                                        error={Boolean(errors.country)}
                                                        fullWidth
                                                        MenuProps={{
                                                            PaperProps: {
                                                                style: {
                                                                    maxHeight: 300, // Set the maxHeight of the Paper
                                                                },
                                                            },
                                                        }}
                                                    >
                                                        <MenuItem disabled value={0} sx={{color: "#767676"}}>
                                                            <em>--Select Country--</em>
                                                        </MenuItem>
                                                        {countries.map((c) => (
                                                            <MenuItem key={c.id} value={c.id}>
                                                                {c.displayName}
                                                            </MenuItem>
                                                        ))}
                                                    </BootstrapSelect>
                                                    {errors.country ? (
                                                        <FormHelperText>{errors.country?.message}</FormHelperText>
                                                    ) : null}
                                                </FormControl>
                                            </Grid>
                                            <Grid md={6} xs={12}>
                                                <FormControl fullWidth required={true}>
                                                    <InputLabel variant="standard" shrink={true} id="region-input-label">
                                                        Region
                                                    </InputLabel>
                                                    <BootstrapSelect
                                                        id="region-input"
                                                        labelId="region-input-label"
                                                        value={regions.length > 0 ? formInfo.regionId : 0}
                                                        name={"regionId"}
                                                        onChange={handleFormChange}
                                                        error={Boolean(errors.region)}
                                                        fullWidth
                                                        MenuProps={{
                                                            PaperProps: {
                                                                style: {
                                                                    maxHeight: 300, // Set the maxHeight of the Paper
                                                                },
                                                            },
                                                        }}
                                                    >
                                                        <MenuItem disabled value={0}>
                                                            <em>--Select Region--</em>
                                                        </MenuItem>
                                                        {regions.length > 0 &&
                                                            regions.map((r) => (
                                                                <MenuItem key={r.id} value={r.id}>
                                                                    {r.regionName}
                                                                </MenuItem>
                                                            ))}
                                                    </BootstrapSelect>
                                                    {errors?.region ? (
                                                        <FormHelperText>{errors.region?.message}</FormHelperText>
                                                    ) : null}
                                                </FormControl>
                                            </Grid>
                                            <Grid md={6} xs={12}>
                                                <FormControlLabel
                                                    control={<Checkbox />}
                                                    name={"poBox"}
                                                    checked={formInfo.poBox}
                                                    label="PO Box"
                                                    onChange={() =>
                                                        setFormInfo({ ...formInfo, poBox: !formInfo.poBox })
                                                    }
                                                />
                                            </Grid>
                                        </Grid>
                                    </Stack>
                                    <Stack spacing={3}>
                                        <Typography variant="h6">Additional information</Typography>
                                        <Grid container spacing={3}>
                                            <Grid md={6} xs={12}>
                                                <FormControl fullWidth required={true}>
                                                    <InputLabel
                                                        variant="standard"
                                                        shrink={true}
                                                        id="time-zone-input-label"
                                                    >
                                                        Time Zone
                                                    </InputLabel>
                                                    <BootstrapSelect
                                                        id="time-zone-input"
                                                        labelId="time-zone-input-label"
                                                        value={formInfo.timeZone}
                                                        name={"timeZone"}
                                                        onChange={handleFormChange}
                                                        error={Boolean(errors.billingAddress?.timeZone)}
                                                        fullWidth
                                                        MenuProps={{
                                                            PaperProps: {
                                                                style: {
                                                                    maxHeight: 300, // Set the maxHeight of the Paper
                                                                },
                                                            },
                                                        }}
                                                    >
                                                        <MenuItem disabled value={0}>
                                                            <em>--Select Time Zone--</em>
                                                        </MenuItem>
                                                        {timeZones.map((t) => (
                                                            <MenuItem key={t.id} value={t.id}>
                                                                {t.name}
                                                            </MenuItem>
                                                        ))}
                                                    </BootstrapSelect>
                                                    {errors.timeZone ? (
                                                        <FormHelperText>{errors.timeZone?.message}</FormHelperText>
                                                    ) : null}
                                                </FormControl>
                                            </Grid>
                                            <Grid md={6} xs={12}>
                                                <FormControl fullWidth required={true}>
                                                    <InputLabel
                                                        variant="standard"
                                                        shrink={true}
                                                        id="language-input-label"
                                                    >
                                                        Language
                                                    </InputLabel>
                                                    <BootstrapSelect
                                                        id="language-input"
                                                        labelId="language-input-label"
                                                        value={formInfo.locale}
                                                        name={"locale"}
                                                        onChange={handleFormChange}
                                                        error={Boolean(errors.billingAddress?.locale)}
                                                        fullWidth
                                                    >
                                                        <MenuItem disabled value={0}>
                                                            <em>--Select Language--</em>
                                                        </MenuItem>
                                                        {locales.map((l) => (
                                                            <MenuItem key={l.id} value={l.id}>
                                                                {l.name}
                                                            </MenuItem>
                                                        ))}
                                                    </BootstrapSelect>
                                                    {errors.locale ? (
                                                        <FormHelperText>{errors.locale?.message}</FormHelperText>
                                                    ) : null}
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </Stack>
                                    {errorsMessages.length > 0 &&
                                        errorsMessages?.map((e, i) => (
                                            <Stack alignItems="center" direction="row" gap={1} key={i}>
                                                <WarningIcon color="error" />
                                                <Typography variant="body1" color="error">
                                                    {e.errorMsg}
                                                </Typography>
                                            </Stack>
                                        ))}
                                </Stack>
                            </CardContent>
                            <CardActions sx={{ justifyContent: "flex-end" }}>
                                <Button
                                    color="secondary"
                                    onClick={() => navigate("/portal/site")}
                                    value={t("base.button_cancel", "cancel")}
                                >
                                    {t("base.button_cancel", "cancel")}
                                </Button>
                                <Button type="submit" variant="contained" value="Save Change">
                                    Save Changes
                                </Button>
                            </CardActions>
                        </Card>
                    </form>
                </Stack>
            </Box>
        </AccountLayout>
    );
}
