import React, { Suspense, lazy } from "react";
import Login from "../pages/Login";
import Home from "../pages/Home";
import PortalLayout from "../layout/Portal/Portal";
import Error404 from "../pages/404";
import Versions from "../pages/Versions";
import TmpPage from "../pages/TmpPage";
import ProtectedRouteWrapper from "../Helpers/PrivateRoute";

// Standard
import LoadingSpinner from "../components/Spinner";
import { EditSiteForm } from "../components/Users/EditSiteForm";
// import ManageUsers from "../pages/SystemAdmin/Manage/ManageUsers";

//#region Public
const AccountActivation = lazy(() => import("../pages/UserAuthPages/AccountActivation"));
const UserAuthNotification = lazy(() => import("../pages/UserAuthPages/Notification"));
const UserAuthEmailStatus = lazy(() => import("../pages/UserAuthPages/EmailStatus"));
const UserAuthEmailVerification = lazy(() => import("../pages/UserAuthPages/EmailVerification"));
const PrivacyPolicyPage = lazy(() => import("../pages/PrivacyPolicy"));
const TermsAndConditionsPage = lazy(() => import("../pages/TermsAndConditions"));
const RequestPasswordReset = lazy(() => import("../pages/UserAuthPages/RequestPasswordReset"));
const ResetPassword = lazy(() => import("../pages/UserAuthPages/ResetPassword"));
//#endregion

//#region Standard
const DashboardPage = lazy(() => import("../pages/StandardPages/Dashboard/Dashboard"));
const MySite = lazy(() => import("../pages/StandardPages/MySite/MySite"));
const AddUser = lazy(() => import("../components/Users/AddUser"));
const UserDetails = lazy(() => import("../components/Users/UserDetails"));
const NewAssessments = lazy(() => import("../pages/StandardPages/NewAssessments"));
const Notifications = lazy(() => import("../pages/StandardPages/Notifications"));
const Orders = lazy(() => import("../pages/StandardPages/TireQuotePage/Orders"));
const Vehicles = lazy(() => import("../pages/StandardPages/TireQuotePage/Vehicles"));
const Tires = lazy(() => import("../pages/StandardPages/TireQuotePage/Tires"));
const Wheels = lazy(() => import("../pages/StandardPages/TireQuotePage/Wheels"));
const Packages = lazy(() => import("../pages/StandardPages/TireQuotePage/Packages"));
const Accessories = lazy(() => import("../pages/StandardPages/TireQuotePage/Accessories"));
const Reports = lazy(() => import("../pages/StandardPages/TireQuotePage/Reports"));
const Order = lazy(() => import("../pages/StandardPages/TireQuotePage/Order"));
const UserProfile = lazy(() => import("../pages/StandardPages/Site/UserProfile"));
const UserSecurity = lazy(() => import("../pages/StandardPages/Site/UserSecurity"));
const Profile = lazy(() => import("../pages/StandardPages/Profile"));
const Security = lazy(() => import("../pages/StandardPages/Security"));
const TireQuotePage = lazy(() => import("../pages/StandardPages/TireQuotePage"));
const ProductDetailPage = lazy(() => import("../pages/StandardPages/TireDetails"));
const Cases = lazy(() => import("../pages/StandardPages/Cases"));
const LmsDashboard = lazy(() => import("../pages/StandardPages/LMS/LmsDashboard"));
const LmsLessons = lazy(() => import("../pages/StandardPages/LMS/Lessons/Lessons"));
const LmsLesson = lazy(() => import("../pages/StandardPages/LMS/Lessons/Lesson"));
const LmsCourses = lazy(() => import("../pages/StandardPages/LMS/Courses/Courses"));
const LmsCourse = lazy(() => import("../pages/StandardPages/LMS/Courses/Course"));
const LmsEnrollment = lazy(() => import("../pages/StandardPages/LMS/Enrollments/Enrollment"));
const LmsEnrollments = lazy(() => import("../pages/StandardPages/LMS/Enrollments/Enrollments"));
const Assessments = lazy(() => import("../pages/StandardPages/Assessments"));
//#endregion

//#region Site Admin
const Pricing = lazy(() => import("../pages/SitesAdmin/TireQuote/Pricing"));
const Featured = lazy(() => import("../pages/SitesAdmin/TireQuote/Featured"));
const Promotions = lazy(() => import("../pages/SitesAdmin/TireQuote/Promotions"));
const Stock = lazy(() => import("../pages/SitesAdmin/TireQuote/Stock"));
const TPO = lazy(() => import("../pages/SitesAdmin/TPO/TPO"));
const Settings = lazy(() => import("../pages/SitesAdmin/TPO/Settings"));
const SitesAdminDashboard = lazy(() => import("../pages/SitesAdmin/Dashboard"));
const TireQuoteTires = lazy(() => import("../pages/SitesAdmin/TireQuote/Data/Tires"));
const TireQuoteWheels = lazy(() => import("../pages/SitesAdmin/TireQuote/Data/Wheels"));
const TireWheelPackages = lazy(() => import("../pages/SitesAdmin/TireQuote/Data/Packages"));
const TireQuoteAccessories = lazy(() => import("../pages/SitesAdmin/TireQuote/Data/Accessories"));
const PricingTemplates = lazy(() => import("../pages/SitesAdmin/TireQuote/Pricing/Templates"));
const BookingWizard = lazy(() => import("../pages/SitesAdmin/TPO/BookingWizard/BookingWizard"));
//#endregion

//#region Portal Admin
const Groups = lazy(() => import("../pages/PortalAdmin/Groups/Groups"));
const OrgAdminDashboard = lazy(() => import("../pages/PortalAdmin/Dashboard"));
const OrgAdminSites = lazy(() => import("../pages/PortalAdmin/Sites/Sites"));
const OrgSite = lazy(() => import("../pages/PortalAdmin/Sites/Site/Site"));
const OrgSiteBooking = lazy(() => import("../pages/PortalAdmin/Sites/Site/Booking"));
const OrgSiteLMS = lazy(() => import("../pages/PortalAdmin/Sites/Site/LMS"));
const OrgSiteTAC = lazy(() => import("../pages/PortalAdmin/Sites/Site/TAC"));
const OrgSiteTireQuote = lazy(() => import("../pages/PortalAdmin/Sites/Site/TireQuote"));
const OrgSiteAssessment = lazy(() => import("../pages/PortalAdmin/Sites/Site/Assessment"));
const OrgSiteUserBooking = lazy(() => import("../pages/PortalAdmin/Users/User/Booking"));
const OrgSiteUserLMS = lazy(() => import("../pages/PortalAdmin/Users/User/LMS"));
const OrgSiteUserTAC = lazy(() => import("../pages/PortalAdmin/Users/User/TAC"));
const OrgSiteUserTireQuote = lazy(() => import("../pages/PortalAdmin/Users/User/TireQuote"));
const OrgSiteUserAssessment = lazy(() => import("../pages/PortalAdmin/Users/User/Assessment"));
const GroupTypes = lazy(() => import("../pages/PortalAdmin/GroupTypes/GroupTypes"));
const OrgAdminTrainingSettings = lazy(() => import("../pages/PortalAdmin/Training/Settings"));
const OrgAdminAssessmentSettings = lazy(() => import("../pages/PortalAdmin/Assessment/Settings"));
const OrgAdminTACSettings = lazy(() => import("../pages/PortalAdmin/TAC/Settings"));
const OrgAdminTrainingCourses = lazy(() => import("../pages/PortalAdmin/Training/Courses/Courses"));
const OrgAdminTrainingCourseCategories = lazy(() => import("../pages/PortalAdmin/Training/Courses/CourseCategories"));
const OrgAdminTrainingLessons = lazy(() => import("../pages/PortalAdmin/Training/Courses/Lessons"));
const PortalUsersList = lazy(() => import("../pages/PortalAdmin/Users/List"));
const PortalTireQuoteSettings = lazy(() => import("../pages/PortalAdmin/TireQuote/Settings"));
const PortalTPOSettings = lazy(() => import("../pages/PortalAdmin/TPO/Settings"));
const PortalUserDetails = lazy(() => import("../pages/PortalAdmin/Users/User/UserDetails"));
const PortalUserSecurity = lazy(() => import("../pages/PortalAdmin/Users/User/UserSecurity"));

//#endregion

//#region System Admin
const MultiOrgAdminDashboard = lazy(() => import("../pages/SystemAdmin/Dashboard"));
const MultiOrgList = lazy(() => import("../pages/SystemAdmin/Organizations/Organizations"));
const TPOSchedules = lazy(() => import("../pages/SystemAdmin/TPO/TPOSchedules"));
const TPOBookings = lazy(() => import("../pages/SystemAdmin/TPO/TPOBookings"));
const AddEditTPOSchedule = lazy(() => import("../pages/SystemAdmin/TPO/AddEditTPOSchedule"));
const TPOSchedule = lazy(() => import("../pages/SystemAdmin/TPO/Schedule/TPOSchdule"));
const SystemAdminOrganization = lazy(() => import("../pages/SystemAdmin/Organizations/Organization"));
const SitesTireQuoteSettings = lazy(() => import("../pages/SitesAdmin/TireQuote/Setting"));
const SystemTPOSettings = lazy(() => import("../pages/SystemAdmin/TireQuote/Settings"));
const SystemTireQuoteSettings = lazy(() => import("../pages/SystemAdmin/TireQuote/Settings"));
const SystemMetric = lazy(() => import("../pages/SystemAdmin/System/Metrics"));
const SystemAccess = lazy(() => import("../pages/SystemAdmin/System/Access"));
const ManageUsers = lazy(() => import("../pages/SystemAdmin/Manage/ManageUsers"));
const SystemAdminContentVehicles = lazy(() => import("../pages/SystemAdmin/Content/Vehicles/Vehicles"));
const SystemAdminContentVehicleBuilds = lazy(() => import("../pages/SystemAdmin/Content/Vehicles/Content/Builds"));
const SystemAdminContentVehicleTypes = lazy(() => import("../pages/SystemAdmin/Content/Vehicles/Content/VehicleTypes"));
const SystemAdminContentTires = lazy(() => import("../pages/SystemAdmin/Content/Tires/Tires"));
const SystemAdminContentTireHomologations = lazy(() =>
    import("../pages/SystemAdmin/Content/Tires/Components/Homologations")
);
const SystemAdminContentPerformanceCategories = lazy(() =>
    import("../pages/SystemAdmin/Content/Tires/Components/PerformanceCatgories")
);
const SystemAdminContentProductGroups = lazy(() =>
    import("../pages/SystemAdmin/Content/Tires/Components/ProductGroups")
);
const SystemAdminContentTireLoads = lazy(() => import("../pages/SystemAdmin/Content/Tires/Components/TireLoads"));
const SystemAdminContentTireSizes = lazy(() => import("../pages/SystemAdmin/Content/Tires/Components/TireSizes"));
const SystemAdminContentTirePrefixes = lazy(() => import("../pages/SystemAdmin/Content/Tires/Components/TirePrefixes"));
const GlobalTire = lazy(() => import("../pages/SystemAdmin/Content/Tires/Tire"));
const GlobalHomologation = lazy(() => import("../pages/SystemAdmin/Content/Tires/Components/Homologation"));
const TPOPurchases = lazy(() => import("../pages/SystemAdmin/TPO/Purchases"));
const OrganizationTPO = lazy(() => import("../pages/SystemAdmin/Organizations/TPO"));
const OrganizationSettings = lazy(() => import("../pages/SystemAdmin/Organizations/Settings"));
//#endregion

const protectedRoute = (Component) => {
    return (
        <Suspense fallback={<LoadingSpinner />}>
            <ProtectedRouteWrapper Component={Component} />
        </Suspense>
    );
};

export const routes = [
    //#region Public
    {
        index: true,
        element: <Home />,
    },
    {
        path: "login",
        element: <Login />,
    },
    {
        path: "activation",
        element: <AccountActivation />,
    },
    {
        path: "notification",
        element: <UserAuthNotification />,
    },
    {
        path: "email-status",
        element: <UserAuthEmailStatus />,
    },
    {
        path: "verify-email",
        element: <UserAuthEmailVerification />,
    },
    {
        path: "terms-and-conditions",
        element: <TermsAndConditionsPage />,
    },
    {
        path: "privacy-policy",
        element: <PrivacyPolicyPage />,
    },
    {
        path: "forgot-password",
        element: <RequestPasswordReset />,
    },
    {
        path: "reset-password",
        element: <ResetPassword />,
    },
    //#endregion
    //#region Standard
    {
        path: "portal",
        element: protectedRoute(PortalLayout),
        children: [
            {
                index: true,
                element: <DashboardPage />,
            },
            {
                path: "tire-quote",
                children: [
                    {
                        index: true,
                        element: <TireQuotePage />,
                    },
                    {
                        path: "orders",
                        element: <Orders />,
                    },
                    {
                        path: "orders/:id",
                        element: <Order />,
                    },
                    {
                        path: "vehicles",
                        element: <Vehicles />,
                    },
                    {
                        path: "tires",
                        element: <Tires />,
                    },
                    {
                        path: "tires/:id",
                        element: <ProductDetailPage />,
                    },
                    {
                        path: "wheels",
                        element: <Wheels />,
                    },
                    {
                        path: "packages",
                        element: <Packages />,
                    },
                    {
                        path: "accessories",
                        element: <Accessories />,
                    },
                    {
                        path: "reports",
                        element: <Reports />,
                    },
                ],
            },
            {
                path: "lms",
                children: [
                    {
                        index: true,
                        element: <LmsDashboard />,
                    },
                    {
                        path: "lessons",
                        element: <LmsLessons />,
                    },
                    {
                        path: "lessons/:id",
                        element: <LmsLesson />,
                    },
                    {
                        path: "courses",
                        element: <LmsCourses />,
                    },
                    {
                        path: "courses/:id",
                        element: <LmsCourse />,
                    },
                    {
                        path: "enrollments",
                        element: <LmsEnrollments />,
                    },
                    {
                        path: "enrollments/:id",
                        element: <LmsEnrollment />,
                    },
                ],
            },
            {
                path: "assessments",
                children: [
                    {
                        index: true,
                        element: <Assessments />,
                    },
                    {
                        path: "new",
                        element: <NewAssessments />,
                    },
                ],
            },
            {
                path: "cases",
                element: <Cases />,
            },
            {
                path: "site",
                children: [
                    {
                        index: true,
                        element: <MySite />,
                    },
                    {
                        path: "edit",
                        element: <EditSiteForm />,
                    },
                    {
                        path: "users/:id",
                        children: [
                            {
                                index: true,
                                element: <UserProfile />,
                            },
                            {
                                path: "security",
                                element: <UserSecurity />,
                            },
                        ],
                    },
                    {
                        path: "add-user",
                        // element: <NewEditUser mode="create" />
                        element: <AddUser />,
                    },
                ],
            },
            {
                path: "account",
                element: <Profile />,
            },
            {
                path: "security",
                element: <Security />,
            },
            {
                path: "notifications",
                element: <Notifications />,
            },
            {
                path: "user",
                element: <UserDetails />,
            },
            {
                path: "versions",
                element: <Versions />,
            },
            {
                path: "*",
                element: <Error404 />,
            },
        ],
    },
    //#endregion
    //#region Site Admin
    {
        path: "admin/sites/:id",
        element: protectedRoute(PortalLayout),
        children: [
            {
                index: true,
                element: <SitesAdminDashboard />,
            },
            {
                path: "tire-quote",
                children: [
                    {
                        path: "data/tires",
                        element: <TireQuoteTires />,
                    },
                    {
                        path: "data/wheels",
                        element: <TireQuoteWheels />,
                    },
                    {
                        path: "data/packages",
                        element: <TireWheelPackages />,
                    },
                    {
                        path: "data/accessories",
                        element: <TireQuoteAccessories />,
                    },
                    {
                        path: "pricing",
                        element: <Pricing />,
                    },
                    {
                        path: "pricing/templates",
                        element: <PricingTemplates />,
                    },
                    {
                        path: "featured",
                        element: <Featured />,
                    },
                    {
                        path: "promotions",
                        element: <Promotions />,
                    },
                    {
                        path: "stock",
                        element: <Stock />,
                    },
                    // {
                    //     path: "tpo",
                    //     element: <TPO />,
                    // },
                    {
                        path: "settings",
                        element: <SitesTireQuoteSettings />,
                    },
                ],
            },
            {
                path: "tpo",
                children: [
                    {
                        index: true,
                        element: <TPO />,
                    },
                    {
                        path: "booking",
                        element: <BookingWizard />,
                    },
                    {
                        path: "booking/:bookingId",
                        element: <BookingWizard />,
                    },
                    {
                        path: "settings",
                        element: <Settings />,
                    },
                ],
            },
        ],
    },
    //#endregion
    //#region Portal Admin
    {
        path: "admin/organizations/:id",
        element: protectedRoute(PortalLayout),
        children: [
            {
                index: true,
                element: <OrgAdminDashboard />,
            },
            {
                path: "sites",
                children: [
                    {
                        // path: "list",
                        index: true,
                        element: <OrgAdminSites />,
                    },
                    {
                        path: ":siteId",
                        children: [
                            {
                                index: true,
                                element: <OrgSite />,
                            },
                            {
                                path: "tire-quote",
                                element: <OrgSiteTireQuote />,
                            },
                            {
                                path: "booking",
                                element: <OrgSiteBooking />,
                            },
                            {
                                path: "lms",
                                element: <OrgSiteLMS />,
                            },
                            {
                                path: "assessment",
                                element: <OrgSiteAssessment />,
                            },
                            {
                                path: "tac",
                                element: <OrgSiteTAC />,
                            },
                        ],
                    },
                    {
                        path: "groups",
                        element: <Groups />,
                    },
                    {
                        path: "group-types",
                        element: <GroupTypes />,
                    },
                ],
            },
            {
                path: "users",
                children: [
                    {
                        index: true,
                        element: <PortalUsersList />,
                    },
                    {
                        path: ":userId",
                        children: [
                            {
                                index: true,
                                element: <PortalUserDetails />,
                            },
                            {
                                path: "security",
                                element: <PortalUserSecurity />,
                            },
                            {
                                path: "permissions",
                                element: <PortalUserSecurity />,
                            },
                            {
                                path: "tire-quote",
                                element: <OrgSiteUserTireQuote />,
                            },
                            {
                                path: "booking",
                                element: <OrgSiteUserBooking />,
                            },
                            {
                                path: "lms",
                                element: <OrgSiteUserLMS />,
                            },
                            {
                                path: "assessment",
                                element: <OrgSiteUserAssessment />,
                            },
                            {
                                path: "tac",
                                element: <OrgSiteUserTAC />,
                            },
                        ],
                    },
                ],
            },
            {
                path: "tire-quote",
                children: [
                    {
                        path: "settings",
                        element: <PortalTireQuoteSettings />,
                    },
                ],
            },
            {
                path: "tpo",
                children: [
                    // {
                    //     index: true,
                    //     element: <TPO />,
                    // },
                    {
                        path: "settings",
                        element: <PortalTPOSettings />,
                    },
                ],
            },
            {
                path: "lms",
                children: [
                    {
                        path: "courses",
                        element: <OrgAdminTrainingCourses />,
                    },
                    {
                        path: "lessons",
                        element: <OrgAdminTrainingLessons />,
                    },
                    {
                        path: "course-categories",
                        element: <OrgAdminTrainingCourseCategories />,
                    },
                    {
                        path: "settings",
                        element: <OrgAdminTrainingSettings />,
                    },
                ],
            },
            {
                path: "assessments",
                children: [
                    {
                        path: "settings",
                        element: <OrgAdminAssessmentSettings />,
                    },
                ],
            },
            {
                path: "tac",
                children: [
                    {
                        path: "settings",
                        element: <OrgAdminTACSettings />,
                    },
                ],
            },
        ],
    },
    //#endregion
    //#region System Admin
    {
        path: "admin/global",
        element: protectedRoute(PortalLayout),
        children: [
            {
                index: true,
                element: <MultiOrgAdminDashboard />,
            },
            {
                path: "organizations",
                children: [
                    {
                        // path: "list",
                        index: true,
                        element: <MultiOrgList />,
                    },
                    {
                        path: ":id",
                        children: [
                            // {
                            //     index: true,
                            //     element: <SystemAdminOrganization />,
                            // },
                            {
                                path: "overview",
                                element: <SystemAdminOrganization />,
                            },
                            {
                                path: "tire-purchases",
                                element: <TPOPurchases />,
                            },
                            {
                                path: "tpo",
                                element: <OrganizationTPO />,
                            },
                            {
                                path: "settings",
                                element: <OrganizationSettings />,
                            },
                        ],
                    },
                ],
            },
            {
                path: "tire-quote",
                children: [
                    // {
                    //     path: "tpo",
                    //     children: [
                    //         {
                    //             path: "schedules",
                    //             element: <TPOSchedules />,
                    //         },
                    //         {
                    //             path: "schedules/:id",
                    //             element: <TPOSchedule />,
                    //         },
                    //         {
                    //             path: "schedules/add",
                    //             element: <AddEditTPOSchedule mode={"create"} />,
                    //         },
                    //         {
                    //             path: "bookings",
                    //             element: <TPOBookings />,
                    //         },
                    //     ],
                    // },
                    {
                        path: "settings",
                        element: <SystemTireQuoteSettings />,
                    },
                ],
            },
            {
                path: "tpo",
                children: [
                    {
                        path: "schedules",
                        element: <TPOSchedules />,
                    },
                    {
                        path: "schedules/add",
                        element: <AddEditTPOSchedule mode={"create"} />,
                    },
                    {
                        path: "schedules/:id",
                        element: <TPOSchedule />,
                    },
                    {
                        path: "bookings",
                        element: <TPOBookings />,
                    },
                    // {
                    //     path: "purchases",
                    //     element: <TPOPurchases />,
                    // },
                    {
                        path: "settings",
                        element: <SystemTPOSettings />,
                    },
                ],
            },
            {
                path: "content",
                children: [
                    {
                        path: "tires",
                        children: [
                            {
                                index: true,
                                element: <SystemAdminContentTires />,
                            },
                            {
                                path: ":tireId",
                                element: <GlobalTire />,
                            },
                            {
                                path: "component",
                                children: [
                                    {
                                        path: "homologations",
                                        children: [
                                            {
                                                index: true,
                                                element: <SystemAdminContentTireHomologations />,
                                            },
                                            {
                                                path: ":homologationId",
                                                element: <GlobalHomologation />,
                                            },
                                        ],
                                    },
                                    {
                                        path: "tire-sizes",
                                        element: <SystemAdminContentTireSizes />,
                                    },
                                    {
                                        path: "performance-categories",
                                        element: <SystemAdminContentPerformanceCategories />,
                                    },
                                    {
                                        path: "product-groups",
                                        element: <SystemAdminContentProductGroups />,
                                    },
                                    {
                                        path: "tire-loads",
                                        element: <SystemAdminContentTireLoads />,
                                    },
                                    {
                                        path: "tire-prefixes",
                                        element: <SystemAdminContentTirePrefixes />,
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        path: "vehicles",
                        children: [
                            {
                                index: true,
                                element: <SystemAdminContentVehicles />,
                            },
                            {
                                path: "component",
                                children: [
                                    {
                                        path: "builds",
                                        element: <SystemAdminContentVehicleBuilds />,
                                    },
                                    {
                                        path: "vehicle-types",
                                        element: <SystemAdminContentVehicleTypes />,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
            {
                path: "lms",
                children: [
                    {
                        path: "courses",
                        element: <TmpPage />,
                    },
                    {
                        path: "lessons",
                        element: <TmpPage />,
                    },
                    {
                        path: "course-categories",
                        element: <TmpPage />,
                    },
                    {
                        path: "settings",
                        element: <TmpPage />,
                    },
                ],
            },
            {
                path: "assessments",
                children: [
                    {
                        path: "settings",
                        element: <TmpPage />,
                    },
                ],
            },
            {
                path: "tac",
                children: [
                    {
                        path: "settings",
                        element: <TmpPage />,
                    },
                ],
            },
            {
                path: "system",
                children: [
                    {
                        path: "insights",
                        children: [
                            {
                                path: "metrics",
                                element: <SystemMetric />,
                            },
                            {
                                path: "access",
                                element: <SystemAccess />,
                            },
                        ],
                    },
                    {
                        path: "manage",
                        children: [
                            {
                                path: "users",
                                children: [
                                    {
                                        index: true,
                                        element: <ManageUsers />,
                                    },
                                    {
                                        path: ":userId",
                                        element: <TmpPage />,
                                    },
                                ],
                            },
                            {
                                path: "groups",
                                element: <TmpPage />,
                            },
                        ],
                    },
                ],
            },
        ],
    },
    //#endregion
    {
        path: "*",
        element: <Error404 />,
    },
];
