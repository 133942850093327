const getTenantName = () => {
    const hostname = window.location.hostname;
    const tenant = hostname.split(".")[0];
    return tenant;
};

const getDomainName = () => {
    const hostname = window.location.hostname; // Get the full domain name
    const parts = hostname.split("."); // Split the domain into parts

    // If the hostname has at least two parts (like subdomain.domain.com),
    // return the second-level and top-level domain
    if (parts.length >= 2) {
        return `${parts[parts.length - 2]}.${parts[parts.length - 1]}`;
    }

    return hostname;
};

const getSubdomain = () => {
   
    // Grab environment variables
    const envType = process.env.REACT_APP_ENVIRONMENT;
    const tenant = process.env.REACT_APP_TENANT;

    // Are we in developer mode?
    if (envType === "DEV" && tenant)
    {
        return tenant;
    }

    return getTenantName();
};

const getDomain = () => {
    const envType = process.env.REACT_APP_ENVIRONMENT;
    const domain = process.env.REACT_APP_DOMAIN;

    // Are we in developer mode?
    if (envType === "DEV" && domain)
    {
        return domain;
    }

    return getDomainName();
};

export { getSubdomain, getDomain };
