// Determine initial drawer state based on screen size
const isSmallScreen = typeof window !== "undefined" && window.innerWidth <= 600;

const componentsReducerDefaultState = {
    drawerOpen: !isSmallScreen, // False if screen is small (<= 600px)
    menuType: "user",
};

// // This is the default state object
// const componentsReducerDefaultState = {
//     drawerOpen: true,
//     menuType: "user",
// };

export default (state = componentsReducerDefaultState, action) => {
    switch (action.type) {
        case "TOGGLE_DRAWER":
            return {
                ...state,
                drawerOpen: !state.drawerOpen,
            };
        case "DRAWER_OPEN": // Opens the main menu drawer
            return {
                ...state,
                drawerOpen: true,
            };
        case "DRAWER_CLOSE": // Close the main menu drawer
            return {
                ...state,
                drawerOpen: false,
            };
        case "UPDATE_MENU_TYPE": // Close the main menu drawer
            return {
                ...state,
                menuType: action.menuType,
            };
        default:
            return state;
    }
};
