const settings = {
    dev_env: {
        env: "PROD", // PROD or DEV
        api_host_dev: "https://services.sapherasaas.com",
        // api_host_prod: "https://services.motorsaas.com",
        api_host_prod: process.env.REACT_APP_SAAS_API_URL,
        api_path: "/api/v1",
        api_request_header_pagination: "x-pagination",
    },
    getSubdomain() {
        const hostname = window.location.hostname;
        const subdomain = hostname.split(".")[0];
        return subdomain;
    },
    api(value = undefined, value2 = undefined) {
        // Set environment variables
        var host = "";
        if (this.dev_env.env === "DEV") {
            host = this.dev_env.api_host_dev;
        } else {
            host = this.dev_env.api_host_prod;
        }

        const route = host + this.dev_env.api_path;

        return {
            host: host,
            route: route,
            domains: route + "/public/domains",
            portalInfo: route + "/public/portal-info",
            authenticate: route + "/authenticate",
            checkToken: route + "/check-token",
            refreshToken: route + "/refresh-token",
            activity: route + "/activity/saas",
            activate: route + "/activate",
            resendValidation: route + "/send-email",
            verifyEmail: route + "/verify-email",
            validatePassword: route + "/public/password/validate",
            passwordReset: route + "/account/password-reset",

            //#region standard
            meSite: route + "/me/site",
            users: route + "/me/site/users",
            userRoles: route + `/me/site/users/roles`,
            userPositions: route + "/me/site/users/positions",
            userDepartments: route + "/me/site/users/departments",
            userResetPassword: route + `/me/site/users/${value}/reset-password`,
            vehicleYear: route + "/vehicles/years",
            meActivity: route + "/me/site/activity",
            account: route + "/account",
            loginSummary: route + `/reporting/sites/${value}/graphs/login-summary`,
            recentUsers: route + "/widgets/saas/recent-users",
            // LMS
            lmsLessons: route + `/services/lms/lessons`,
            lmsCategoriesFilter: route + `/services/lms/lookups/categories`,
            lmsCoursesFilter: route + `/services/lms/lookups/courses`,
            lmsStreamsFilter: route + `/services/lms/lookups/streams`,
            lmsCourses: route + "/services/lms/courses",
            //#endregion
            
            //#region site Admin
            groups: route + "/admin/groups",
            organizations: route + "/admin/organizations",
            organizationUsers: route + `/admin/organizations/${value}/users`,
            organizationSites: route + `/admin/organizations/${value}/sites`,
            organizationGroupTypes: route + `/admin/organizations/${value}/group-types`,
            siteTpoSchedules: route + `/admin/sites/${value}/tpo/schedules`,
            // scheduleBookings: route + `/admin/sites/${value}/tpo/schedules/{scheduleId}/bookings`,
            tpoBookings: route + `/admin/sites/${value}/tpo/bookings`,
            bookingProducts: route + `/admin/sites/${value}/tpo/bookings/${value2}/products`,
            bookingDelivery: route + `/admin/sites/${value}/tpo/bookings/${value2}/delivery`,
            bookingReview: route + `/admin/sites/${value}/tpo/bookings/${value2}/review`,
            bookingSubmit: route + `/admin/sites/${value}/tpo/bookings/${value2}/submit`,
            manual: route + `/admin/sites/${value}/tpo/schedules/help`,
            tpoSettings: route + `/admin/sites/${value}/tpo/settings`,
            //#endregion

            //#region portal Admin
            portalAdminUsers: route + `/admin/organizations/${value}/users`,
            orgCategories: route + `/admin/organizations/${value}/lms/categories`,
            siteMenu: route + `/admin/organizations/${value}/sites/${value2}/menu`,
            portalAdminSite: route + `/admin/organizations/${value}/sites/${value2}`,
            siteUsers: route + `/admin/organizations/${value}/sites/${value2}/users`,
            siteUserMenu: route + `/admin/organizations/${value}/users/${value2}/menu`,

            //#endregion

            //#region System/Global Admin
            globalOrganizations: route + "/admin/global/organizations",
            globalOrganization: route + `/admin/global/organizations/${value}`,
            organizationMenu: route + `/admin/global/organizations/${value}/menu`,
            organizationTirePurchase: route + `/admin/global/organizations/${value}/tire-purchases`,
            tpoSchedules: route + "/admin/global/tpo/schedules",
            tpoSchedulesBookings: route + `/admin/global/tpo/schedules/${value}/bookings`,
            tpoScheduleOrganizations: route + "/admin/global/tpo/schedules/organizations",
            tpoScheduleProducts: route + "/admin/global/tpo/schedules/product-types",
            tpoScheduleTypes: route + "/admin/global/tpo/schedules/types",
            tpoScheduleData: route + `/admin/global/tpo/schedules/${value}/data`,
            tpoScheduleActivity: route + `/admin/global/tpo/schedules/${value}/activity`,
            tpoDeliveryDates: route + `/admin/global/organizations/${value}/tpo/delivery-dates`,
            quantitySuggestions: route + `/admin/global/tpo/schedules/${value}/suggestions`,
            quantitySuggestionsImport: route + `/admin/global/tpo/schedules/${value}/suggestions/import`,

            importScheduleData: route + `/admin/global/tpo/schedules/${value}/data/import`,
            downloadSchedulesBooking: route + `/admin/global/tpo/schedules/${value}/booking-export`,
            downloadSchedulesProgress: route + `/admin/global/tpo/schedules/${value}/progress-export`,
            systemMetrics: route + "/system/metrics",
            activeUsers: route + "/admin/global/system/reports/active-users",
            totalRequests: route + "/admin/global/system/reports/total-requests",
            avgResponse: route + "/admin/global/system/reports/average-response",
            cpuUsage: route + "/admin/global/system/reports/cpu-usage",
            manageUser: route + "/admin/global/system/manage/users",
            globalTires: route + "/admin/global/content/tires",
            globalHomologation: route + "/admin/global/content/tires/homologations",
            globalTireDetails: route + `/admin/global/content/tires/${value}`,
            //#endregion

            //#region settings
            standardMenu: route + "/system/menus/standard",
            structure: route + "/system/structure",
            siteAdminMenu: route + "/system/structure/site",
            portalAdminMenu: route + "/system/structure/organization",
            systemAdminMenu: route + "/system/structure/global",
            content: route + "/system/translations/content",
            timezones: route + "/system/timezones",
            countries: route + "/system/countries",
            regions: route + `/system/countries/${value}/regions`,
            updateLanguage: route + "/update-language",
            checkLanguage: route + "/check-language",
            translations: route + "/translations",
            versions: route + "/system/versions",
            tpoUpcomingWidget: route + "/widgets/tpo/upcoming",
            //#endregion
        };
    },
};
export default settings;
